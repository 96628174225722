/**
 * @Author: yangzhenhua
 * @Date:   2023-03-19 10:46:07
 * @Last Modified by:   yangzhenhua
 * @Last Modified time: 2023-03-31 18:16:49
 */
import axios from "axios";
import store from "../store";
import config from "../config"
import Cookies from "js-cookie";
const service = axios.create({
    baseURL: config.baseURL,
    timeout: 8000
});

service.interceptors.request.use(
    (config) => {
        config.headers["token"] = store.getters.getToken;
        // config.headers["adminId"] = Cookies.get('admin_id');
        config.headers["content-type"] = 'application/x-www-form-urlencoded';
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

service.interceptors.response.use(
    (response) => {
         

        console.log(response,'response')
        if (response.status === 200) {
            if (response.data.code === 200) {
                return Promise.resolve(response.data.data);
            } if (response.data.code === 1) {
                return Promise.resolve(response.data);
            } else if (response.data.code === 403) {
                store.commit("loginout");
                return Promise.reject(response.data);
            } else if (response.data.code === -1) {
                 
                store.commit("loginout");
                return Promise.reject(response.data);
            }else {
                return Promise.reject(response.data);
            }
        } else {
            return Promise.reject({message: '网络错误'});
        }
    },
    (error) => {
        return Promise.reject({message: '网络错误'});
    }
);

export default service;
