/**
 * @Author: yangzhenhua
 * @Date:   2023-03-25 20:38:14
 * @Last Modified by:   yangzhenhua
 * @Last Modified time: 2023-04-03 09:57:08
 */
import axios from "axios";

import config from "../config"
/**
 * 16进制转字符串
 *    @description 16进制转字符串
 *    @param {String} str 16进制文件流
 *    @return {String} 字符串
 */
export function HexToString(str) {
    if (str.length % 2 != 0) {
        alert('必须为偶数');
        return ''
    }
    let a;
    let string = [];
    for (var i = 0; i < str.length; i = i + 2) {
        a = parseInt(str.substr(i, 2), 16);
        string.push(String.fromCharCode(a));
    }
    return string.join('');
}


/**
 * 字符串转16进制
 * @param {String} str  -传入要转换的字符串
 * @return {String} -返回转换后的16进制字符串
 */
export function StringToHex(str) {
    if (str === "")
        return "";
    var hexCharCode = [];
    hexCharCode.push("0x");
    for (var i = 0; i < str.length; i++) {
        hexCharCode.push((str.charCodeAt(i)).toString(16));
    }
    return hexCharCode.join("");
}

/**
 * 时间戳转化为年 月 日 时 分 秒
 * @param {Number,Stying} number - 传入时间戳或时间字符串
 * @param {String} format：返回格式，支持自定义，但参数必须与formateArr里保持一致
 * @return {String} 返回格式化之后的字符串
 * @example
 * formatTime('2020/08/27 11:01:22','Y/M/D h:m:s')
 * formatTime('2020/08/27 11:01:22','Y/M/D h:m:s')
 * formatTime('2020-08-27 11:01:22','Y/M/D h:m:s')
 * formatTime('1598497282','Y/M/D h:m:s')
 * formatTime('1598497282000','Y/M/D h:m:s')
 */
export function formatTime(number, format = 'Y-M-D h:m:s') {
    let Timestamp = ''
    let number_str = ""

    if (number) {
        number_str = number.toString() //先转成字符串
    } else {
        number_str = "1900-01-01 00:00:00"
    }


    if (number_str.length == 13) {
        // 如果是13位时间戳
        Timestamp = parseInt(number_str)

    } else if (number_str.length == 10) {
        // 如果是10位时间戳

        Timestamp = (number_str * 1000)

    } else {

        // 如果是时间字符串
        if (number_str.indexOf("-") != -1) {
            Timestamp = number_str.replace(/-/g, "/")
        } else {
            Timestamp = number_str;
        }
    }


    var formateArr = ['Y', 'M', 'D', 'h', 'm', 's'];
    var returnArr = [];

    var date = new Date(Timestamp);
    returnArr.push(date.getFullYear());
    returnArr.push(formatNumber(date.getMonth() + 1));
    returnArr.push(formatNumber(date.getDate()));
    returnArr.push(formatNumber(date.getHours()));
    returnArr.push(formatNumber(date.getMinutes()));
    returnArr.push(formatNumber(date.getSeconds()));

    for (var i in returnArr) {
        format = format.replace(formateArr[i], returnArr[i]);
    }
    return format;
}

/**
 *  数据转化-如果字符串或数字不足两位，手动在前面加上0返回两位的字符串
 * @param {String,Number} n -传入的字符串或数字
 * @return {String,Number} --手动在前面加上0返回两位的字符串
 */
function formatNumber(n) {
    n = n.toString()
    return n[1] ? n : '0' + n
}


/**
 * 隐藏中间部分
 * @param {String,Number} str -原字符串
 * @param {Number}   frontLen -前面显示几位
 * @param {Number}  endLen -后面显示几位
 * @example
 * plusXing('18888888888',3,4)    //188****8888
 */

export function plusXing(str, frontLen = 3, endLen = 4) {
    if (!str) return ""
    var len = str.length - frontLen - endLen;
    var xing = '';
    for (var i = 0; i < len; i++) {
        xing += '*';
    }
    if (str.length === 1) {
        return str + '*';
    } else if (str.length == 2) {
        return str.substring(0, frontLen) + '*' + str.substring(str.length - endLen);
    } else {
        return str.substring(0, frontLen) + xing + str.substring(str.length - endLen);
    }
}



/**
 * 文件下载
 * @param url
 * @param fileName
 * @returns {Promise}
 */
//api.js
export function exportExcel(url, fileName) {



    return new Promise((resolve, reject) => {
        axios.defaults.headers['content-type'] = 'application/json;charset=UTF-8'
        axios({
            method: 'get',
            url: url, // 请求地址
            responseType: 'blob' // 表明返回服务器返回的数据类型
        }).then(
            response => {
                let blob = new Blob([response.data], {type: 'application/vnd.ms-excel'})
                let file_name = `${fileName ? fileName : Date.parse(new Date())}.xlsx`
                if (window.navigator.msSaveOrOpenBlob) {
                    navigator.msSaveBlob(blob, file_name)
                } else {
                    var link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = file_name
                    link.click()
                    window.URL.revokeObjectURL(link.href)  //释放内存

                }
                resolve(response.data)
            },
            err => {
                reject(err)
            }
        )
    })
}

export function exportExcel1(reqq, params) {

    return new Promise((resolve, reject) => {
        var cs = ''
        for(var i in params){
            cs = cs?cs+'&'+i+'='+params[i]:i+'='+params[i]
        }
        var url = config.baseURL+reqq+'?'+cs
        window.open(url)
    })
}
 

/**
 * 按身份证号码获取性别
 * @idNumber 身份证号码
 * @return 男：0；女：1；异常（身份证号码为空或长度、格式错误）：undefined
 */
export function getGenderByIdNumber(idNumber) {
    if (idNumber) {
        let genderCode; // 性别代码
        if (idNumber.length == 18) { // 二代身份证号码长度为18位（第17位为性别代码）
            genderCode = idNumber.charAt(16);
        } else if (idNumber.length == 15) { // 一代身份证号码长度为15位（第15位为性别代码）
            genderCode = idNumber.charAt(14);
        }
        if (genderCode && !isNaN(genderCode)) {
            // 两代身份证号码的性别代码都为男奇女偶
            if (parseInt(genderCode) % 2 == 0) {
                return 1;
            }
            return 0;
        }
    } else {
        return undefined
    }
}


// 分割数组
export function getNewArray(array, subGroupLength) {
    let index = 0;
    let newArray = [];
    while (index < array.length) {
        newArray.push(array.slice(index, index += subGroupLength));
    }
    return newArray;
}





