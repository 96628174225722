/**
 * @Author: yangzhenhua
 * @Date:   2023-03-25 20:38:14
 * @Last Modified by:   yangzhenhua
 * @Last Modified time: 2023-04-03 09:54:45
 */
import h5Copy from '@/common/copy.js'
import {mapState} from "vuex"
import {formatTime} from "@/utils";
import config from "@/config"

export default {
    data() {
        return {
            request: false,
            loading_show: null
        }
    },
    computed: {
        ...mapState({
            user: 'user_info',
        }),
        //    上传文件api
        uploadURL() {
            return `${this.$config.baseURL}/api/common/upload`
        }
    },
    methods: {
          //参数展示
          cszh(detail){
            var html = ''
            for(var i in detail){
                var value = detail[i];
                html = html?html+i+':'+value+'</br>':html+i+':'+value+'</br>'
            }
            return html;
          },

        formatTime,
        getUserInfo(type = null) {
            // return new Promise((resolve, reject) => {
            //     this.$api.get("/api/rule/set_personal").then(res => {
            //         this.$store.commit("setUserInfo", res.data)
            //         resolve(res.data)
            //     }).catch(err => {
            //         this.apiErr(err)
            //         resolve()
            //     })
            // })
        },
        exportExcel1(reqq, params) {

            return new Promise((resolve, reject) => {
                var cs = ''
                for(var i in params){
                    cs = cs?cs+'&'+i+'='+params[i]:i+'='+params[i]
                }
                var url = config.baseURL+reqq+'?'+cs

                console.log(url,'url')
               
                window.open(url)
            })
        },
        // 获取右上角备忘预警数据
        getNoticeMsg() {
            this.$api.get("/api/manager/my_reminder").then(res => {
                console.log("我的备忘预警", res.data)
                this.$store.commit('setNoticeList', res.data)

            }).catch(err => {
                this.apiErr(err)
            })
        },

        goBack() {
            window.history.back()
        },
        // 复制
        copy(content) {
            const result = h5Copy(content)
            if (result === false) {
                this.fail('复制失败')
            } else {
                this.success('复制成功')
            }
        },

        openWin(url) {
            this.$router.push(url).then(res => {
            }).catch(err => {
            })
        },
        openNewWin(url) {
            window.open('/web/#' + url)
        },
        //打开外部网页
        openWeb(url) {
            if (!url) return false;
            window.open(url)
        },
        // 提示框
        toast(text, duration = 1.2) {
            this.$message.info(text, duration);
        },
        // 成功提示框
        success(text, duration = 1) {
            this.$message.success(text, duration);
        },
        // 成功提示框
        successBox(text, duration = 1.5) {
            this.$notify({
                title: '成功',
                message: text,
                type: 'success',
                
                duration: duration * 1000
            });
        },
        // 错误提示框
        fail(text, duration = 1.2) {
            this.$message.error(text, duration);
        },
        // 显示加载框  //不会自动关闭
        loadingShow(text = '加载中', time = 10) {
            this.loading_show = this.$loading({
                lock: true,
                text: text,
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 0.7)'
            });

            if (time > 0) {
                setTimeout(() => {
                    this.loading_show.close();
                }, time * 1000);

            }

            // this.$store.commit('setLoading', {active: true, text: text})
            // if (time > 0) {
            //     setTimeout(() => {
            //         this.loadingHide()
            //     }, time)
            // }
        },
        // 关闭加载框
        loadingHide() {
            if (this.loading_show) this.loading_show.close();

            // this.$store.commit('setLoading', {active: false})
        },

        // 接口报错提示
        apiErr(err) {
            this.loadingHide()
            if (err.message) {
                this.fail(err.message)
            } else if (err.msg) {
                this.fail(err.msg)
            } else {
                this.fail('系统错误')
            }
        },
        // 防抖处理
        isRequest() {
            if (this.request) return true;
            this.request = true;
            setTimeout(() => {
                this.request = false;
            }, 2000)
            return false;
        },
        //格式化性别
        formatterGender(e) {
            if (e.gender === 0) {
                return "男"
            } else if (e.gender === 1) {
                return "女"
            } else {
                return "未知"
            }
        },

        downloadBlob(blobUrl, fileName = '', revokeObjectURL) {
            let downloadElement = document.createElement('a')
            downloadElement.href = blobUrl
            //下载后文件名
            downloadElement.download = fileName
            document.body.appendChild(downloadElement)
            //点击下载
            downloadElement.click()
            //下载完成移除元素
            document.body.removeChild(downloadElement)
            if (revokeObjectURL == null || revokeObjectURL) {
                //释放掉blob对象
                window.URL.revokeObjectURL(blobUrl)
            }
        },
        //tabel格式化
        formatterContent(row, column, cellValue, index) {
            return cellValue || '--'
        },
        // 格式化申请人姓名
        formatterApplyName(row) {
            if (row.is_party === 0) {
                //  委托案件
                return `${row.username}(委托案件)`
            }
            if (row.people_type === 2) {
                return `${row.username}(企业)`
            }
            return `${row.username}`

        },

        getImageSrc(src) {
            if (!src) return ''
            if (src.charAt(0) === '/') return config.baseURL + src //图片在服务器
            return src
        },
        uploadImage(file) {

        },
        //查看按钮权限
        isShowBtn(id) {
            
            const _id = String(id)
            if (this.user.rules === '*') return true
            
            const ids = this.user.rules.split(',')
            // console.log(ids,ids.indexOf(_id),'idid')
            return ids.indexOf(_id) > -1;
        },

        //转换字符串
        szzh(value,num=3){
            if(!Number(value)||value=='Infinity'){
                value = 0
                return value
            }

            return  Number(value).toFixed(num)
        }

    }
}
