import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import config from "./config"
import api from "./api/index.js"
import base from "./mixins/index.js"

import permission from "./router/permission"
import TDesign from 'tdesign-vue';
import 'tdesign-vue/es/style/index.css';

Vue.use(TDesign);


Vue.prototype.$config = config
Vue.prototype.$api = api
Vue.mixin(base)

import ElementUI from 'element-ui';
import './assets/theme/index.css';


import VueWechatTitle from 'vue-wechat-title'

Vue.use(VueWechatTitle)

Vue.use(ElementUI);

let echarts = require('echarts')

Vue.prototype.$echarts = function (el) {
    return echarts.init(el, null, { renderer: 'svg' })
}
import IBack from "@/components/i-back";
Vue.component('i-back', IBack)


import IIcon from "@/components/i-icon";
Vue.component('i-icon', IIcon)


import ZELTable from "@/components/table/src/table";
Vue.component('zel-table', ZELTable)

import editor from "@/components/editor/index";
Vue.component('editor', editor)

import Cookies from "js-cookie";

Vue.prototype.$cookie = Cookies


// 引入element-tiptap
import { ElementTiptapPlugin } from 'element-tiptap';
// import element-tiptap 样式
import 'element-tiptap/lib/index.css';
//使用
Vue.use(ElementTiptapPlugin, {
    lang: 'zh',
});


if (process.env.NODE_ENV !== 'development') console.log = () => {
} //生产模式



Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
