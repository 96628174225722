<template>
  <div class="editor-container">
    <Toolbar :editor="editorRef" :mode="mode" />
    <Editor
      :mode="mode"
      :defaultConfig="editorConfig"
      :style="{ height }"
      v-model="editorVal"
      @onCreated="handleCreated"
      @onChange="handleChange"
    />
  </div>
</template>

<script name="wngEditor">
import "@wangeditor/editor/dist/css/style.css";
import { IDomEditor } from "@wangeditor/editor";
import { Toolbar, Editor } from "@wangeditor/editor-for-vue";
import config from "@/config/index.js";
// type InsertFnType = (url: string, alt: string, href: string) => void;
// const emit = defineEmits(['update:getHtml', 'update:getText']); 
const uploadUrl=""
export default {
  components:{
    Toolbar,Editor
  },
  props: {
    disable: {
      type: Boolean,
      default: () => false,
    },
    // 内容框默认 placeholder
    placeholder: {
      type: String,
      default: () => "请输入内容...",
    },
    // https://www.wangeditor.com/v5/getting-started.html#mode-%E6%A8%A1%E5%BC%8F
    // 模式，可选 <default|simple>，默认 default
    mode: {
      type: String,
      default: () => "default",
    },
    // 高度
    height: {
      type: String,
      default: () => "310px",
    },
    // 双向绑定，用于获取 editor.getHtml()
    getHtml: String,
    // 双向绑定，用于获取 editor.getText()
    getText: String,
  },
  data() {
    return {
      editorConfig: {
        placeholder: this.placeholder,
        MENU_CONF: {
          uploadImage: {
            server: config.baseURL + "/api/common/upload",
            fieldName: "file",
            customInsert(res, insertFn) {
           
              insertFn(res.data.fullurl, "", res.data.fullurl);
            },
          },uploadVideo: {
            server: config.baseURL + "/api/common/upload",
            fieldName: "file",
            customInsert(res, insertFn) {
           
              insertFn(res.data.fullurl, "", res.data.fullurl);
            },
          },
        },
      },
      editorVal:this.getHtml,
      editorRef:""
    };
  },
  watch: {
    disable(bool) {
      const editor = this.editorRef;
      if (editor == null) return;
      bool ? editor.disable() : editor.enable();
    },
    getHtml(val) {
      this.editorVal = val;
    },
  },
  methods: {
    // 编辑器回调函数
    handleCreated(editor) {
      this.editorRef = editor;
    },
    // 编辑器内容改变时
    handleChange(editor) {
      // console.log(editor,'editoreditor')
      // console.log( editor.getHtml())
      this.$emit("ttchange", editor.getHtml());
      // this.$emit("update:getText", editor.getText());
    },
  },
  mounted() {
    // console.log("getHtml:",this.getHtml)
  },
  onBeforeUnmount(){
	const editor = this.editorRef;
	if (editor == null) return;
	editor.destroy();
  }
};  
</script>

<style scoped>
.editor-container{
  border: solid #eee 1px;
}
</style>
