import router from "@/router";
import Layout from "@/views/layout";
import store from "@/store";
import api from "@/api";

//动态获取路由
const dynamicRouters = () => {
    return new Promise(function (resolve, reject) {
        api.get('/index/rule/get_user_menu').then((res) => {
            const routers = res.data.map((e) => {
                e.component = (e.component === 'Layout') ? Layout : loadView(e.component)
                e.children = e.children.map(item => {
                    item.component = (item.component === 'Layout') ? Layout : loadView(item.component)
                    return item
                })
                return e
            })
            routers.forEach((e) => {
                if (e.path) {
                    router.addRoute(e)
                }
            })
            store.commit('setMenuList', routers)
            console.log(routers,'routers')
            resolve(routers);
        }).catch((err) => {
            console.log('获取路由菜单出错', err)
            reject()
        })
    })
}


// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap, lastRouter = false, type = false) {
    return asyncRouterMap.filter(route => {
        if (type && route.children) {
            route.children = filterChildren(route.children)
        }
        if (route.component) {
            // Layout ParentView 组件特殊处理
            if (route.component === 'Layout') {
                route.component = Layout
            } else if (route.component === 'ParentView') {
                route.component = ParentView
            } else if (route.component === 'InnerLink') {
                route.component = InnerLink
            } else {
                route.component = loadView(route.component)
            }
        }
        if (route.children != null && route.children && route.children.length) {
            route.children = filterAsyncRouter(route.children, route, type)
        } else {
            delete route['children']
            delete route['redirect']
        }
        return true
    })
}

function filterChildren(childrenMap, lastRouter = false) {
    var children = []
    childrenMap.forEach((el, index) => {
        if (el.children && el.children.length) {
            if (el.component === 'ParentView' && !lastRouter) {
                el.children.forEach(c => {
                    c.path = el.path + '/' + c.path
                    if (c.children && c.children.length) {
                        children = children.concat(filterChildren(c.children, c))
                        return
                    }
                    children.push(c)
                })
                return
            }
        }
        if (lastRouter) {
            el.path = lastRouter.path + '/' + el.path
        }
        children = children.concat(el)
    })
    return children
}


export const loadView = (view) => {
    if (process.env.NODE_ENV === 'development') {
        return (resolve) => require([`@/views/${view}`], resolve)
    } else {
        // 使用 import 实现生产环境的路由懒加载
        // return () => import(`@/views/${view}`)
        // 解决webpack4 不支持变量方式的动态 import
        return (resolve) => require([`@/views/${view}`], resolve)
    }
}
console.log('tototototototo')
// debugger

export default dynamicRouters
