import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from "@/views/layout";
import store from "@/store"; 
Vue.use(VueRouter)

//公共路由
export const constantRoutes = [
    // {
    //     path: "/",
    //     redirect: "/home",
    // },
    {
        path: '/login',
        hidden: true,
        meta: {title: '欢迎登录', login: false},
        component: () => import('../views/login/login')
    },

    {
        path: '/activetySz',
        meta: {
            title: '',
            content: {
                keywords: '',
                description: ''
            }
        },
        redirect:"/activetySz",
        component: layout,
        children:[{
            path: '/activetySz',
            name: 'activetySz',
            component: () => import('../views/activity/activetySz')
        }],
    } 
     
    // {
    //     /**
    //      * 进行我们的路由匹配，通俗来讲就是比如我们是http://localhost:8080
    //      * 匹配我们/后面的如果你乱写的话就会重定向我们的404路由
    //      * 举个例子
    //      *   { path: '/user-:afterUser(.*)',      redirect: '/notfound},
    //      * 匹配我们/user后面的内容，如果你乱写的话就会进入404
    //      */
    //     path: '/:pathMatch(.*)*',
    //     redirect: '/404'
    // }
]


// 动态路由，基于用户权限动态去加载
export const dynamicRoutes = []

const createRouter = () => new VueRouter({
    mode: 'hash',
    scrollBehavior: () => ({y: 0}),
    base: process.env.BASE_URL,
    routes: constantRoutes
})
const router = createRouter()

//写一个重置路由的方法，切换用户后，或者退出时清除动态加载的路由
export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // 新路由实例matcer，赋值给旧路由实例的matcher，（相当于replaceRouter）
}

// //全局前置守卫
// router.beforeEach((to, from, next) => {
     
//     //第一个参数to，包含的内容是切换后的路由对象，也就是跳转后的路由对象
//     //第二个参数from，包含的内容的是切换前的路由对象，也就是跳转前的路由对象
//     //第三个参数next()，是否往下执行，执行的话，如果不写的话路由就不会跳转，操作将会终止
//     if (store.getters.getToken) {
//         store.commit('setToken', store.getters.getToken)
//     }
 
//     console.log('tototototototo111111111',router)

//     // if (!to.meta.login) return next()   //不需要验证登录
//     if (store.state.user_info && store.state.user_info.user_id) {
//         //已经登录了
//         return next()
//     }
//     // //走到这需要跳转到登录页
//     // router.push('/login')

// })


export default router
