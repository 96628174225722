<template>
  <div class="dialog-view">
    <el-dialog title="修改密码" :visible.sync="dialogVisible" width="700px">
      <el-form label-width="120px" :model="params" :rules="rules" ref="ruleForm">
        <el-form-item label="原登录密码：" prop="pass_old">
          <el-input v-model="params.pass_old" type="password" placeholder="请输入原登录密码" @keydown.native.enter="onConfirm()"></el-input>
        </el-form-item>
        <el-form-item label="新密码：" prop="pass_new">
          <el-input v-model="params.pass_new" type="password" placeholder="请输入新密码" @keydown.native.enter="onConfirm()"></el-input>
        </el-form-item>
        <el-form-item label="确认密码 ：" prop="pass_con">
          <el-input v-model="params.pass_con" type="password" placeholder="请重复输入新密码" @keydown.native.enter="onConfirm()"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onConfirm()">确认</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
export default {
  name: "change-password",
  data() {
    return {
      dialogVisible: false,
      params: {
        pass_old: "",
        pass_new: "",
        pass_con: "",
      },
      rules: {
        pass_old: [
          {required: true, message: '请输入原登录密码', trigger: 'blur'},
        ],
        pass_new: [
          {required: true, message: '请设置新登录密码', trigger: 'blur'},
        ],
        pass_con: [
          {required: true, message: '请再次输入新密码', trigger: 'blur'},
          {
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error("请再次输入新密码"));
              } else if (value !== this.params.pass_new) {
                callback(new Error("两次输入密码不一致!"));
              } else {
                callback();
              }
            },
            trigger: "blur"
          }
        ],
      },

    }
  },
  methods: {
    show() {
      this.params = {
        pass_old: "",
        pass_new: "",
        pass_con: "",
      }
      this.dialogVisible = true
    },
    onConfirm() {
      this.$refs.ruleForm.validate((valid) => {
        if (!valid) return false
        const params = {
          newpassword: this.params.pass_new,
          oldpassword: this.params.pass_old,
        }
        this.$api.post('/index/rule/edit_passwd', params).then(() => {
          this.successBox("密码修改成功")
          this.dialogVisible = false
          setTimeout(() => {
            this.$store.commit('loginout')
          }, 200)
        }).catch((err) => {
          this.apiErr(err)
        })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.dialog-footer {
  display: flex;
  justify-content: center;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #385FF2;
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    border-radius: 4px;
    font-size: 16px;
    color: #FFFFFF;
    margin-right: 15px;
    cursor: pointer;
  }

  span:nth-child(2) {
    background: #ffffff;
    border: 1px solid #E9EBF1;
    color: #252631;
  }
}
</style>
